.plyr-background-video,
.plyr-background-video .plyr {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 0;
}

.plyr-background-video video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  background-color: white;
}

.plyr-background-video .plyr__video-embed__container {
  padding-bottom: 0 !important;
  transform: none !important;
  height: 100% !important;
}

.plyr-background-video iframe {
  width: calc(100% / 9 * 16) !important;
  height: 100%;
  left: 50% !important;
  transform: translateX(-50%);
}
