.pyxis-btn {
  padding: 0 1.5rem;
  font-weight: bold;
  max-width: 100%;
  cursor: pointer;
  display: inline-flex;
  grid-auto-flow: column;
  justify-content: center;
  place-items: center;
  position: relative;
  transition-duration: 200ms;
  transition-property: background-position, border-color, box-shadow, color, outline,
    -webkit-transform;
  transition-property: background-position, border-color, box-shadow, color, outline,
    transform;
  transition-property: background-position, border-color, box-shadow, color, outline,
    transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.2, 0.07, 0.38, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: background-position, border-color, box-shadow, color, outline,
    transform;
}

.prose a:has(.pyxis-btn) {
  text-decoration: none;
}

[disabled] > .pyxis-btn,
[disabled] > .pyxis-btn:hover,
[disabled] > .pyxis-btn:focus {
  background: #dddee1;
  border-color: transparent;
  color: #90939d;
  cursor: not-allowed;
}

/* Sizes */

.pyxis-btn-huge {
  min-height: 3.125rem;
  /* min-width: 13.75rem; */
  padding: 0 1.5rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  line-height: 1.5625rem;
}

@media only screen and (min-width: 768px) {
  .pyxis-btn-huge {
    font-size: 1rem;
  }
}

.pyxis-btn-large {
  min-height: 2.5rem;
  /* min-width: 10rem; */
  padding: 0 1.5rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  line-height: 1.5625rem;
}

@media only screen and (min-width: 768px) {
  .pyxis-btn-large {
    font-size: 1rem;
  }
}

.pyxis-btn-medium {
  min-height: 1.875rem;
  /* min-width: 7.5rem; */
  padding: 0 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.pyxis-btn-small {
  min-height: 1.5rem;
  min-width: 5rem;
  padding: 0 0.75rem;
  border-radius: 2rem;
  font-size: 0.75rem;
  line-height: 1.1875rem;
}

/* Primary */

.pyxis-btn-primary {
  outline-color: rgba(6, 127, 150, 0.2);
  background-image: linear-gradient(45deg, #067f96 50%, #07a5b6);
  background-position: 100% 0;
  background-size: 200%;
  color: #fff;
}

.pyxis-btn-primary:hover {
  background-position: 0 100%;
  box-shadow: 0 4px 12px -2px rgb(6 127 150 / 40%);
}

.pyxis-btn-primary:focus {
  outline: rgba(6, 127, 150, 0.2) solid 0.1875rem;
  outline-offset: 0.125rem;
}

/* Secondary */

.pyxis-btn-secondary {
  outline-color: rgba(6, 127, 150, 0.2);
  border: 0.125rem solid #067f96;
  color: #067f96;
}

.pyxis-btn-secondary:hover {
  box-shadow: 0 4px 12px -2px rgb(6 127 150 / 15%);
  border-color: #067f96;
}

.pyxis-btn-secondary:focus {
  outline: rgba(6, 127, 150, 0.2) solid 0.1875rem;
  outline-offset: 0.125rem;
}

/* Tertiary */

.pyxis-btn-tertiary {
  outline-color: rgba(6, 127, 150, 0.2);
  border: 0.125rem solid #dddee1;
  color: #067f96;
}

.pyxis-btn-tertiary:hover {
  box-shadow: 0 4px 12px -2px rgb(6 127 150 / 15%);
  border-color: #067f96;
}

.pyxis-btn-tertiary:focus {
  outline: rgba(6, 127, 150, 0.2) solid 0.1875rem;
  outline-offset: 0.125rem;
}

/* Brand */

.pyxis-btn-brand {
  outline-color: rgba(6, 127, 150, 0.2);
  background-image: linear-gradient(45deg, #5b2488 50%, #8334c2);
  background-position: 100% 0;
  background-size: 200%;
  color: #fff;
}

.pyxis-btn-brand:hover {
  background-position: 0 100%;
}

.pyxis-btn-brand:focus {
  outline: rgba(6, 127, 150, 0.2) solid 0.1875rem;
  outline-offset: 0.125rem;
}

/* White */

.pyxis-btn-white {
  background-color: #ffffff;
  outline-color: rgba(255, 255, 255, 0.2);
  color: #21283b;
}

.pyxis-btn-white:hover {
  color: #067f96;
}

.pyxis-btn-white:focus {
  outline: rgba(255, 255, 255, 0.2) solid 0.1875rem;
  outline-offset: 0.125rem;
}

/* Alt */

.pyxis-btn-alt {
  background: transparent;
}

/* Ghost */

.pyxis-btn-ghost {
  outline-color: rgba(6, 127, 150, 0.2);
  border-bottom: 0.125rem solid rgba(0, 0, 0, 0);
  color: #067f96;
  padding-top: 0.125rem;
  border-radius: 0;
}

.pyxis-btn-ghost > span {
  border-bottom: 2px solid transparent;
}

.pyxis-btn-ghost:hover > span {
  border-bottom-color: #067f96;
}

.pyxis-btn-ghost:focus {
  outline: rgba(6, 127, 150, 0.2) solid 0.1875rem;
  outline-offset: 0.125rem;
}

/* Badge */

.pyxis-badge {
  border-radius: 0.5rem;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.1875rem;
  color: #21283b;
  background-color: #f3f4f4;
  display: inline-block;
  padding: 0.125rem 0.5rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pyxis-badge-brand {
  background-color: #f2eaf8;
  color: #5b2488;
}

.pyxis-badge-white {
  background-color: #fff;
  color: #8334c2;
}

.pyxis-badge-action {
  background-color: #e5f1f4;
  color: #045767;
}

.pyxis-badge-success {
  background-color: #e8f2ec;
  color: #145a31;
}

.pyxis-badge-alert {
  background-color: #fff8dc;
  color: #21283b;
}

.pyxis-badge-neutral-gradient {
  background: linear-gradient(45deg, #21283b, #595d6a);
  color: #fff;
}

.pyxis-badge-brand-gradient {
  background: linear-gradient(45deg, #5b2488, #8334c2);
  color: #fff;
}

.pyxis-badge-ghost {
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
}

.pyxis-badge-alt {
  background-color: white;
}
