// we don't want to include pyxis base, as it adds global styling resets
// @use '~@pyxis/scss/src/scss/base';

@use '~@pyxis/scss/src/scss/config';
@use '~@pyxis/scss/src/scss/base/form';
@use '~@pyxis/scss/src/scss/foundations' as pyxis;

@use '~@pyxis/scss/src/scss/components/accordion';
@use '~@pyxis/scss/src/scss/components/badge';
@use '~@pyxis/scss/src/scss/components/buttonCard';
// @use '~@pyxis/scss/src/scss/components/buttonRow';
@use '~@pyxis/scss/src/scss/components/buttons';
// @use '~@pyxis/scss/src/scss/components/counter';
// @use '~@pyxis/scss/src/scss/components/dropdownMenu';
@use '~@pyxis/scss/src/scss/components/forms';
// @use '~@pyxis/scss/src/scss/components/grid'; // this messes up the website grid
// @use '~@pyxis/scss/src/scss/components/icons';
// @use '~@pyxis/scss/src/scss/components/link';
// @use '~@pyxis/scss/src/scss/components/loaders';
// @use '~@pyxis/scss/src/scss/components/message';
// @use '~@pyxis/scss/src/scss/components/modal';
// @use '~@pyxis/scss/src/scss/components/progressIndicator';
// @use '~@pyxis/scss/src/scss/components/tabs';
// @use '~@pyxis/scss/src/scss/components/toast';
// @use '~@pyxis/scss/src/scss/components/tooltip';

svg {
  fill: currentColor; // fixes pyxis button card icons colour
}
